import { IdTokenResult } from "@firebase/auth";
import {
  KikCustomerEmailUpdateCompletionParams as CustomerEmailUpdateCompletionParams,
  KikCustomerEmailUpdateFields as CustomerEmailUpdateFields,
  KikCustomerLoginFields,
  KikCustomerPasswordResetParams as CustomerPasswordResetParams,
  KikCustomerPasswordResetRequestFields as CustomerPasswordResetRequestFields,
  KikCustomerPasswordUpdateFields as CustomerPasswordUpdateFields,
  KikCustomerSendEmailVerificationFields,
  KikCustomerSignUpCompletionParams as CustomerSignUpCompletionParams,
  KikCustomerSignUpFields as CustomerSignUpFields,
  KikCustomerSignUpWithActionCodeFields,
  KikErrorMessages as ErrorMessages,
  KikFirebaseCustomClaims as FirebaseCustomClaims,
  KikGenericResponseBase,
  KikProspectSignUpCompletionParams as ProspectSignUpCompletionParams,
  KikProspectSignUpFields as ProspectSignUpFields,
  KikRestVerbs as RestVerbs,
  KikSocialSignInKeys as SocialSignInKeys,
  KikSocialSignInUxModes as SocialSignInUxModes,
  KikUnsubscribeNewsletterParams as UnsubscribeNewsletterParams,
  KikUserZodSchemas as ZodSchemas,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { AuthenticationOpenID } from "@springtree/eva-services-authentication-openid";
import { Core } from "@springtree/eva-services-core";
import { HttpStatusCode } from "axios";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { useCart } from "~/hooks/use-cart";
import { useGlobalNotification } from "~/hooks/use-global-notification";
import Eva from "~/services/eva";
import Firebase from "~/services/firebase";
import { HttpConfig, HttpService } from "~/services/http";
import Logger from "~/services/logger/logger";
import { StorageFactory } from "~/services/storage.factory";
import { ApiConstants } from "~/shared/api/api-constants.class";
import { ApiUtils } from "~/shared/api/api-utils.class";
import { ErrorResponse } from "~/shared/api/error-response.class";
import { EvaUserAccountType } from "~/shared/eva/eva-user-account-type.enum";
import { MarketingCloudSendEmailParams } from "~/shared/marketing-cloud/marketing-cloud-send-email.model";
import { LoginSignUpOrigins } from "~/shared/user/login-sign-up-origins.enum";
import AppRoutes from "~/utils/app-routes";
import CommonUtils from "~/utils/common-utils";
import invariant from "~/utils/invariant";
import TrackingUtils from "~/utils/tracking-utils";

type AuthCallConfigBody =
  | CustomerEmailUpdateCompletionParams
  | CustomerEmailUpdateFields
  | CustomerSignUpCompletionParams
  | CustomerSignUpFields
  | ProspectSignUpCompletionParams
  | ProspectSignUpFields
  | UnsubscribeNewsletterParams
  | MarketingCloudSendEmailParams;

type AuthCallConfig = Partial<Pick<HttpConfig<AuthCallConfigBody>, "body" | "method" | "queryParams" | "headers">> & {
  addAuth?: boolean;
};

interface EvaHookHttpError extends Error {
  response: Response;
}

enum AuthSessionStorageKeys {
  signalRToken = "signalRToken",
}

const useAuth = (initialStates?: { isLoading?: boolean }) => {
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(!0);
  const [isLoading, setIsLoading] = useState(!!initialStates?.isLoading);
  const [firebaseToken, setFirebaseToken] = useState<string | void>();
  const [firebaseIdTokenResult, setFirebaseIdTokenResult] = useState<IdTokenResult | void>();
  const [authError, setAuthError] = useState<ErrorResponse | null>(null);
  const [providerId, setProviderId] = useState<number>(-1);

  const unsubscribeAuthObs = useRef<() => void>();

  const [currentUserToken, setCurrentUserToken] = useRecoilState(state.currentUser.currentUserTokenState);
  const [socialProvider, setSocialProvider] = useState<SocialSignInKeys | null>();
  const cart = useCart()?.ShoppingCart;
  const user = hooks.useGetServiceResponse(state.currentUser.currentUserState)?.User;
  const { newErrorGlobalNotification } = useGlobalNotification();
  const router = useRouter();
  //
  const sessionStorageFactory = new StorageFactory<AuthSessionStorageKeys.signalRToken>("sessionStorage");

  const onLoginEvaError = (error: Error) => {
    throw error;
  };

  // EVA HOOKS - START
  const evaCartModCreateCustomer = hooks.useShoppingCartModify({
    service: Core.CreateCustomer,
  });
  const evaCartModLogin = hooks.useShoppingCartModify({
    service: Core.Login,
    options: {
      onError: onLoginEvaError,
    },
  });
  const evaServiceLogin = hooks.useCallService({
    service: Core.Login,
  });
  const evaServiceLogout = hooks.useCallService({
    service: Core.Logout,
  });
  const evaServiceOpenIdConfigs = hooks.useCallService({
    service: AuthenticationOpenID.GetAvailableOpenIDConfigurations,
  });
  const refreshUserData = hooks.useSetServiceStale({ serviceState: state.currentUser.currentUserState });
  const updateUser = hooks.useCallService({ service: Core.UpdateUser });
  // EVA HOOKS - END

  // PRIVATE

  const _checkRedirectResult = () => {
    Logger.instance.debug("use-auth: checkRedirectResult requested");
    setIsLoading(!0);
    Firebase.instance
      .getRedirectResult()
      .finally(() => setIsLoading(!1))
      .then(function (result) {
        if (result && result.providerId) {
          const provider = Firebase.instance.providersRelation[result.providerId];
          _checkSocialUser(provider);
        }
      })
      .catch((error) => {
        Logger.instance.error(`use-auth: getRedirectResult errored`, error);
        newErrorGlobalNotification(
          {
            title: "generic.error",
            description: ApiUtils.errorFactory(error).messages![0].message || ErrorMessages.GENERIC_ERROR,
          },
          { timeout: 7500 }
        );
      });
  };

  const _checkSocialUser = async (socialKey: SocialSignInKeys) => {
    setIsLoading(!0);
    const firebaseUser = Firebase.instance.loggedUser;
    Logger.instance.info("use-auth: social login success", firebaseUser);
    const t = await firebaseUser?.getIdToken();
    // Update the firebaseToken state
    setFirebaseToken(() => t);
    try {
      await _doCall(ApiConstants.endpoints.authSocialCustomer, {
        headers: {
          Authorization: `Bearer ${t}`,
        },
      });
      // IF WE GOT A USER AND IT'S A CUSTOMER ON EVA BASICALLY WE CAN LOG IN, OTHERWISE IT COULD BE A REGISTRATION/CONVERSION
      setSocialProvider(socialKey);
    } catch (err: any) {
      // 404 means I can register, not a real error, so I need to clear up the authError
      if (err?.status === HttpStatusCode.NotFound) {
        setAuthError(null);
        const redirectUrl = new URL(["", router.locale, AppRoutes.SIGN_UP].join("/"), location.href);
        redirectUrl.searchParams.set("social", socialKey);

        router.push(redirectUrl.href, redirectUrl.pathname);
      }
    }
  };

  const _doCall = async <T = void>(
    url: string,
    { body, queryParams = {}, method, addAuth, headers = {} }: AuthCallConfig
  ): Promise<T> => {
    setIsLoading(!0);
    setAuthError(null);
    try {
      return await HttpService.$http<T>({
        url,
        method: method || (body ? RestVerbs.POST : RestVerbs.GET),
        body,
        queryParams,
        headers: {
          ...(addAuth ? getFirebaseTokenAuthHeader() : {}),
          ...headers,
        },
      });
    } catch (e: any) {
      Logger.instance.error("use-auth: call errored", {
        endpoint: url,
        error: e,
      });
      setAuthError(e.body || new ErrorResponse());
      // We rethrow to avoid having if-else outside
      return Promise.reject(e);
    } finally {
      setIsLoading(!1);
    }
  };

  const _orchestrateLoginMethod = async (idToken: string): Promise<EVA.Core.LoginResponse | void> => {
    let innerProviderId = providerId;
    if (innerProviderId < 0) {
      innerProviderId = await _retrieveOpenIdProviderId();
      setProviderId(innerProviderId);
    }
    const loginPayload = Eva.buildSSOLoginPayload(idToken, innerProviderId);
    try {
      Logger.instance.debug("use-auth: orchestrated login cart", cart);
      const loginResp =
        cart?.ID && !cart?.Customer?.ID
          ? await evaCartModLogin({
              payload: loginPayload,
            })
          : await evaServiceLogin(
              loginPayload,
              { authenticationToken: "" },
              {
                onError: onLoginEvaError,
              }
            );

      if (loginResp?.Authentication === 2 && loginResp.User) {
        const token = loginResp.User.AuthenticationToken;
        _signalRCleanUp();
        setCurrentUserToken(token);
      }
    } catch (e) {
      Logger.instance.error("use-auth: orchestrated login failure", e);
      let response: { FailureReason?: number } | void = void 0;
      try {
        response = await (e as EvaHookHttpError).response.json();
      } catch (e) {}
      Logger.instance.warn("Handling EVA error", {
        response,
      });
      setAuthError(ErrorResponse.build(ErrorMessages.GENERIC_ERROR, response?.FailureReason));
      return Promise.reject(e);
    }
  };

  const _retrieveOpenIdProviderId = async (): Promise<number> => {
    const configs = await evaServiceOpenIdConfigs(undefined, { authenticationToken: "" }).then(
      (r) => r?.Providers || []
    );
    const config = configs.find((c) => c.Name === process.env.NEXT_PUBLIC_OPEN_ID_PROVIDER_NAME);

    return config?.ID || -1;
  };

  const _signalRCleanUp = () => {
    sessionStorageFactory.remove(AuthSessionStorageKeys.signalRToken);
  };

  const _verifyIdToken = () => {
    return Firebase.instance
      .verifyIdToken()
      .catch((e) => Logger.instance.warn("use-auth: idToken verification failed", e));
  };

  // PUBLIC

  /**
   * Perform a login on Eva using the existing Firebase token
   */
  const autoLogin = async (): Promise<ErrorResponse | void> => {
    Logger.instance.debug("use-auth: calling autoLogin");
    setIsLoading(!0);
    setAuthError(null);
    setSocialProvider(null);
    try {
      // Extract the id_token from the returned authentication object
      const firebaseIdToken = await Firebase.instance.loggedUser?.getIdToken();
      setFirebaseToken(firebaseIdToken); // Need to force this otherwise the asynchronicity breaks with redirects
      await _orchestrateLoginMethod(firebaseIdToken!);
    } catch (err: any) {
      Logger.instance.error("use-auth: login errored", err);
      setAuthError(ApiUtils.errorFactory(err));
      return Promise.reject();
    } finally {
      setIsLoading(!1);
    }
  };

  const checkActionCode = async (code: string) => {
    setIsLoading(!0);
    setAuthError(null);
    return Firebase.instance
      .checkActionCode(code)
      .finally(() => setIsLoading(!1))
      .catch((err) => {
        Logger.instance.error("use-auth: checkActionCode errored", err);
        setAuthError(ApiUtils.errorFactory(err));
        // We rethrow to avoid having if-else outside
        return Promise.reject();
      });
  };

  const createGuestCustomer = async (order: EVA.Core.OrderDto) => {
    invariant(!!order.BillingAddress, `Order is being used to create customer but no address is defined.`);

    let user: EVA.Core.CreateCustomer.Customer = {
      DateOfBirth: ZodSchemas.guestBirthdayDto.toISODate()!,
      FirstName: order.BillingAddress.FirstName,
      LastName: order.BillingAddress.LastName,
      EmailAddress: order.BillingAddress.EmailAddress,
      BillingAddress: order.BillingAddress,
    };

    if (order.ShippingAddress) {
      user.ShippingAddress = order.ShippingAddress;
    }

    const customer = await evaCartModCreateCustomer({
      payload: {
        OrderID: order.ID,
        AutoLogin: true,
        AccountType: EvaUserAccountType.Incognito,
        AttachToOrder: true,
        User: user,
      },
    });

    if (customer?.User.AuthenticationToken) {
      _signalRCleanUp();
      setCurrentUserToken(customer.User.AuthenticationToken);
    }

    return customer;
  };

  const emailUpdateCompletion = (data: CustomerEmailUpdateCompletionParams) => {
    return _doCall(ApiConstants.endpoints.authEmailUpdateCompletion, {
      method: RestVerbs.POST,
      body: data,
    }).then(() => logout());
  };

  const emailUpdateRequest = async (params: CustomerEmailUpdateFields) => {
    return _doCall(ApiConstants.endpoints.authEmailUpdate, {
      addAuth: !0,
      method: RestVerbs.POST,
      body: params,
    });
  };

  const getFirebaseTokenAuthHeader = (): { Authorization: string } | {} => {
    return firebaseToken ? { Authorization: `Bearer ${firebaseToken}` } : {};
  };

  const login = async (loginFields: KikCustomerLoginFields): Promise<ErrorResponse | void> => {
    setIsLoading(!0);
    setAuthError(null);
    try {
      await Firebase.instance.setPersistence(
        CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_FIREBASE_FORCE_PERSISTENCE) || !!loginFields.RememberMe
      );
      const user = await Firebase.instance.signInWithEmailAndPassword(loginFields.EmailAddress, loginFields.password);

      // Extract the id_token from the returned authentication object
      const firebaseIdToken = await user?.getIdToken();
      setFirebaseToken(firebaseIdToken); // Need to force this otherwise the asynchronicity breaks with redirects
      await _orchestrateLoginMethod(firebaseIdToken!);
    } catch (err: any) {
      Logger.instance.error("use-auth: login errored", err);
      setAuthError(ApiUtils.errorFactory(err));
    } finally {
      setIsLoading(!1);
    }
  };
  const loginAsCustomer = async (
    loginFields: Omit<KikCustomerLoginFields, "RememberMe">
  ): Promise<ErrorResponse | void> => {
    try {
      await logout().catch((e) => Logger.instance.warn("use-auth: couldn't logout", e));
      const customToken = await _doCall<
        KikGenericResponseBase<{
          token: string;
        }>
      >(ApiConstants.endpointsV2.authLoginAsCustomer, {
        body: loginFields,
      }).then((r) => r.data?.token!);
      const token = await Firebase.instance
        .signInWithCustomToken(customToken)
        .then((credential) => credential?.user.getIdToken());
      _orchestrateLoginMethod(token);
    } catch (err: any) {
      Logger.instance.error("use-auth: login errored", err);
    }
  };

  const logout = async (): Promise<ErrorResponse | void> => {
    setIsLoading(!0);
    setAuthError(null);
    try {
      // Sign out from Google
      await Firebase.instance.signOut().catch((e) => Logger.instance.warn("use-auth: Firebase logout failed", e));
      // Sign out from EVA
      await evaServiceLogout().catch((e) => Logger.instance.warn("use-auth: EVA logout failed", e));
      await _signalRCleanUp();
    } catch (err: any) {
      Logger.instance.error("use-auth: logout errored", err);
      setAuthError(new ErrorResponse());
    } finally {
      setIsLoading(!1);
    }
  };

  const passwordChange = (params: CustomerPasswordUpdateFields) => {
    setIsLoading(!0);
    setAuthError(null);

    return Firebase.instance
      .updatePassword(params.oldPassword, params.password)
      .finally(() => setIsLoading(!1))
      .then(() => logout())
      .catch((err) => {
        Logger.instance.error("use-auth: updatePassword errored", err);
        setAuthError(ApiUtils.errorFactory(err));
        // We rethrow to avoid having if-else outside
        return Promise.reject();
      });
  };

  const passwordReset = (params: CustomerPasswordResetParams, newPassword: string) => {
    setIsLoading(!0);
    setAuthError(null);

    return Firebase.instance
      .confirmPasswordReset(params.oobCode, newPassword)
      .finally(() => setIsLoading(!1))
      .catch((err) => {
        Logger.instance.error("use-auth: passwordReset errored", err);
        setAuthError(ApiUtils.errorFactory(err));
        // We rethrow to avoid having if-else outside
        return Promise.reject();
      });
  };

  const passwordResetRequest = async (data: CustomerPasswordResetRequestFields) => {
    return _doCall(ApiConstants.endpoints.authPasswordReset, {
      queryParams: data,
    });
  };

  const sendEmailVerification = <
    T extends Pick<CustomerSignUpFields, "EmailAddress" | "CountryID" | "LanguageID" | "FirstName">
  >(
    signUpFields: T
  ): Promise<void> => {
    return _doCall(ApiConstants.endpointsV2.authSendEmailVerification, {
      body: {
        EmailAddress: signUpFields.EmailAddress,
        FirstName: signUpFields.FirstName,
        CountryID: signUpFields.CountryID,
        LanguageID: signUpFields.LanguageID,
      } as KikCustomerSendEmailVerificationFields,
    }).then((r) => {
      TrackingUtils.track(TrackingUtils.mapUserEvent("sign_up_requested", { method: "email" }));
      return r;
    });
  };

  const signalRToSessionStorage = (token: string): void => {
    return sessionStorageFactory.set(AuthSessionStorageKeys.signalRToken, token);
  };

  const signInWithSocialProvider = async (socialKey: SocialSignInKeys): Promise<void> => {
    setIsLoading(!0);
    setAuthError(null);
    setSocialProvider(null);
    Logger.instance.info("use-auth: social login requested");
    try {
      await Firebase.instance.signInWithSocialProvider(socialKey);
      // Extract the id_token from the returned authentication object
    } catch (err: any) {
      Logger.instance.warn("use-auth: signInWithSocialProvider errored", err);
      setIsLoading(() => !1);
      // Firebase errors need to be put into authError
      setAuthError(ApiUtils.errorFactory(err));

      return Promise.reject();
    }

    _checkSocialUser(socialKey);
  };

  const signUp = async (signUpFields: CustomerSignUpFields, origin?: LoginSignUpOrigins): Promise<void> => {
    return _doCall(ApiConstants.endpoints.authCustomer, {
      body: signUpFields,
      queryParams: {
        origin: origin || "",
      },
    }).then((r) => {
      TrackingUtils.track(TrackingUtils.mapUserEvent("sign_up_requested", { method: "email" }));
      return r;
    });
  };

  const signUpCompletion = async (signUpCompletionParams: CustomerSignUpCompletionParams): Promise<void> => {
    return _doCall(ApiConstants.endpoints.authSignUpCompletion, {
      body: signUpCompletionParams,
    }).then(() => {
      TrackingUtils.track(TrackingUtils.mapUserEvent("sign_up", { method: "email" }));
    });
  };

  const signUpCustomerWithActionCode = (
    signUpCustomerWithActionCodeFields: KikCustomerSignUpWithActionCodeFields,
    origin?: LoginSignUpOrigins
  ) => {
    Logger.instance.debug("use-auth: signUpCustomerWithActionCode", {
      signUpCustomerWithActionCodeFields,
      origin,
    });
    return _doCall(ApiConstants.endpointsV2.authCustomer, {
      body: signUpCustomerWithActionCodeFields,
      queryParams: {
        origin: origin || "",
      },
    }).then((r) => {
      TrackingUtils.track(TrackingUtils.mapUserEvent("sign_up", { method: "email" }));
      return r;
    });
  };

  const signUpProspect = async (prospectSignUpFields: ProspectSignUpFields): Promise<void> => {
    return _doCall(ApiConstants.endpoints.authProspect, {
      body: prospectSignUpFields,
    })
      .then(() => {
        TrackingUtils.track(
          TrackingUtils.mapUserEvent("generate_lead", { lead_type: "newsletter", lead_result: "submit_ok" })
        );
        return Promise.resolve();
      })
      .catch(() => {
        TrackingUtils.track(
          TrackingUtils.mapUserEvent("generate_lead", { lead_type: "newsletter", lead_result: "submit_ko" })
        );
        return Promise.reject();
      });
  };

  const signUpProspectCompletion = async (signUpCompletionParams: ProspectSignUpCompletionParams): Promise<void> => {
    return _doCall(ApiConstants.endpoints.authSignUpProspectCompletion, {
      body: signUpCompletionParams,
    });
  };

  const signUpSocial = async (signUpFields: FirebaseCustomClaims, socialKey: SocialSignInKeys): Promise<void> => {
    return _doCall(ApiConstants.endpoints.authSocialCustomer, {
      body: signUpFields,
      queryParams: {
        origin,
      },
    }).then((r) => {
      TrackingUtils.track(TrackingUtils.mapUserEvent("sign_up_requested", { method: socialKey }));
      return r;
    });
  };

  const unsubscribeNewsletterCompletion = async (
    unsubscribeNewsletterParams: UnsubscribeNewsletterParams
  ): Promise<void> => {
    return _doCall(ApiConstants.endpoints.authProspect, {
      method: RestVerbs.DELETE,
      queryParams: {
        ...unsubscribeNewsletterParams,
      },
    });
  };

  /**
   * Updates a guest customer with data coming from an order.
   * For example if a guest user performs two orders, we must update the attributes (e.g. email, name...)
   * This updates the customer retroactively, so old orders will display the new data.
   */
  const updateGuestCustomer = async (order: EVA.Core.OrderDto) => {
    invariant(order.CustomerID, `Order is being used to update customer, but no customer is associated.`);
    invariant(order.BillingAddress, `Order is being used to update customer, but has no address.`);

    await updateUser({
      ID: order.CustomerID,
      FirstName: order.BillingAddress.FirstName,
      LastName: order.BillingAddress.LastName,
      EmailAddress: order.BillingAddress.EmailAddress,
    });
  };

  const updateUserProfile = (firstName: string, lastName: string) => {
    setIsLoading(!0);
    setAuthError(null);

    return Firebase.instance
      .updateUserProfile({
        displayName: `${firstName} ${lastName}`,
      })
      .catch((err) => {
        Logger.instance.error("use-auth: updateUserProfile errored", err);
        setAuthError(ApiUtils.errorFactory(err));
        // We rethrow to avoid having if-else outside
        return Promise.reject();
      })
      .finally(() => setIsLoading(!1));
  };

  const hasEvaAuth = !!currentUserToken && !!user?.ID; // Do not consider anonymous users as authenticated

  useEffect(() => {
    if (Firebase.instance.socialLoginUXMode && !isLoading) {
      Logger.instance.warn("use-auth: useEffect checkRedirectResult", Firebase.instance.socialLoginUXMode);
      Firebase.instance.socialLoginUXMode !== SocialSignInUxModes.POPUP && _checkRedirectResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Firebase.instance.socialLoginUXMode]);

  useEffect(() => {
    unsubscribeAuthObs.current?.(); // Turn off previous listeners
    unsubscribeAuthObs.current = Firebase.instance.addAuthStateChangedCallback(async (firebaseUser) => {
      let newToken: string | void = void 0;
      let newIdTokenResult: IdTokenResult | void = void 0;
      // A new user session is started
      if (firebaseUser) {
        newToken = await firebaseUser.getIdToken();
        newIdTokenResult = await _verifyIdToken();
        if (user) {
          if (user.AccountType !== EvaUserAccountType.Standard) {
            Logger.instance.warn("use-auth: Firebase token with non standard user! Performing Firebase logout!");
            await Firebase.instance.signOut(); // Logout from Firebase only
          } else if (user.EmailAddress !== firebaseUser.email) {
            Logger.instance.warn("use-auth: Firebase/EVA user mismatch! Performing logout!");
            logout();
          }
        }
      } else {
        const signalR = sessionStorageFactory.get(AuthSessionStorageKeys.signalRToken);
        if (user) {
          // So basically if user is customer, but we don't have a signalR, or there is a signalR, but it doesn't match the user token, then cleanup
          if (
            (!signalR && user.AccountType !== EvaUserAccountType.Incognito) ||
            (signalR && signalR !== currentUserToken)
          ) {
            Logger.instance.warn(
              "use-auth: found customer EVA user but no firebase session and no signalR, cleaning up!"
            );
            _signalRCleanUp(); // void custom SignalR backup
            setCurrentUserToken(""); // void EVA session
          }
        }
      }
      setFirebaseToken(newToken);
      setFirebaseIdTokenResult(newIdTokenResult);
      setIsLoadingUser(!1);
    });

    return () => {
      unsubscribeAuthObs.current?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserToken, user, firebaseToken]);

  useEffect(() => {
    if (socialProvider && !isLoading) {
      Logger.instance.info(`SocialSignIn: autologin can be performed for ${socialProvider}!`);
      autoLogin().then(() => {
        Logger.instance.debug(`SocialSignIn: autologin complete with ${socialProvider}!`);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialProvider, isLoading]);

  return {
    // Values
    authError,
    currentUserToken,
    enabledSocials: Firebase.instance.enabledSocials,
    firebaseToken,
    firebaseIdTokenResult,
    firebaseUser: Firebase.instance.loggedUser,
    hasEvaAuth,
    isAuthenticated: !!firebaseToken && hasEvaAuth && user.AccountType === EvaUserAccountType.Standard,
    isLoading,
    isLoadingUser,
    isMigrated: new RegExp(process.env.NEXT_PUBLIC_CID_PATTERN!).test(user?.BackendID || ""),
    socialProvider,
    user,
    // Methods
    autoLogin,
    checkActionCode,
    createGuestCustomer,
    emailUpdateCompletion,
    emailUpdateRequest,
    login,
    loginAsCustomer,
    logout,
    passwordChange,
    passwordReset,
    passwordResetRequest,
    refreshUserData,
    sendEmailVerification,
    signalRToSessionStorage,
    signInWithSocialProvider,
    signUp,
    signUpCompletion,
    signUpCustomerWithActionCode,
    signUpProspect,
    signUpProspectCompletion,
    signUpSocial,
    unsubscribeNewsletterCompletion,
    updateUserProfile,
    updateGuestCustomer,
  };
};

export default useAuth;
