import { UseFormProps } from "react-hook-form/dist/types";

const Constants = {
  DEFAULT_DEBOUNCE_TIME: 300,
  DEFAULT_LOCALE: "en-ae",
  DEFAULT_PLP_STALE_TIME: 10 * 60 * 1000, // 10 minutes
  DEFAULT_STALE_TIME: 60 * 1000, // 1 min
  DEFAULT_REDIRECT_TIME: 10 * 1000, // 10 seconds
  DEFAULT_UNDO_TIME: 5 * 1000, // 10 seconds
  DEFAULT_STATIC_PAGE_TIME: 10 * 60, // 10 minutes
  DEFAULT_CART_UPDATE_TIMEOUT: 30 * 1000, // 30 seconds
  SHIPPING_STALE_TIME: 60 * 1000 * 60, // 1 hour
  DEFAULT_PAGE_SIZE: 16,
  MAXIMUM_PAGE_SIZE: 100,
  ORDERS_PAGE_SIZE: 6,
  PRODUCT_MEDIA_DROPLET_NAMES: ["droplet", "goccia"],
  PRODUCT_MEDIA_GALLERY_ORDER: ["primary", "secondary", "tertiary", "application", "swatch"],
  HEADER_HEIGHT_MOBILE: 76,
  HEADER_HEIGHT_DESKTOP: 112,
  HEADER_TOPBAR_HEIGHT_MOBILE: 32,
  HEADER_TOPBAR_HEIGHT_DESKTOP: 36,
  DEFAULT_GUEST_BIRTHDATE: "1990-01-01T00:00:00.000Z",
  SAVE_FOR_LATER_WISHLIST_NAME: "KIKO-SAVEFORLATER",
  QUERY_CHILDREN_INCLUDED_FIELDS: [
    "color",
    "display_price",
    "parent_product_ids",
    "product_media",
    "product_types",
    "slug",
  ],
  QUERY_INCLUDED_FIELDS: [
    "backend_id",
    "color",
    "currency_id",
    "display_price",
    "display_value",
    "ecomm_max_items_per_order",
    "ecomm_online_category_master",
    "logical_level",
    "original_price",
    "parent_product_ids",
    "product_media",
    "product_types",
    "review_average_score",
    "review_total_number",
    "short_description",
    "slug",
  ],
  CART_INCLUDED_FIELDS: [
    "backend_id",
    "color",
    "currency_id",
    "display_price",
    "display_value",
    "ecomm_max_items_per_order",
    "ecomm_online_category_master",
    "original_price",
    "parent_product_ids",
    "product_media",
    "product_types",
    "slug",
  ],
  ORDER_DETAIL_INCLUDED_FIELDS: [
    "backend_id",
    "color",
    "currency_id",
    "display_price",
    "display_value",
    "ecomm_online_category_master",
    "parent_product_ids",
    "product_media",
    "product_types",
    "slug",
  ],
  PRODUCT_TYPES: {
    GiftCard: 8,
    VirtualProduct: 4096,
  } as const,
  LOYALTY_PROGRAM_BACKEND_ID: "LoyaltyProgram",
  LOYALTY_PROGRAM_TIER_BACKEND_ID: "Tier",
  LOYALTY_PROGRAM_TIER_EXPIRATION_BACKEND_ID: "TierExpirationDate",
  DIDOMI_SPECIAL_LINK: "/didomi-special-link/",
  MARKETING_OPT_IN_BACKEND_ID: "MarketingOptIn",
  PREFERRED_COLLECT_ID: "PreferredCollectStoreID",
  GENERIC_PDP_SEO: "generic_pdp_seo",
  GIFT_WRAPPING_MESSAGE_MAXLENGTH: 150,
  ORDER_TYPE_RESERVE: "ClickandReserveStoreToStore",
  RETURN_ORDER: 131072,
  ENVIRONMENT_LABELS_KEY_NAME: "disposal",
  LOYALTY_PUSH_AUTOCLOSE_TIME: 10000,
  LOYALTY_PUSH_LOCALSTORAGE_KEY: "kikoLoyaltyPushAlreadyClosed",
  COUPON_KEY: "fpVoucherCode",
  USABLENET_CONTAINER_KEY: "usntA40Toggle",
  USABLENET_LINK_KEY: "usntA40Link",
  ENABLE_USABLENET_COUNTRIES: ["en-us"],
  SWIPER_TRANSITION_DURATION: 400,
  USE_FORM_VALIDATION_DEFAULTS: {
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
  } as Pick<UseFormProps, "mode" | "reValidateMode" | "shouldFocusError">,
  SCROLLBAR_CLASSNAME: "kiko-scrollbar",
  RETURN_ADDITIONAL_INFO: "ADDITIONAL_INFO",
  ECOMM_MAX_ITEMS_PER_ORDER: "ecomm_max_items_per_order",
  PUDO_ID: "PUDO_ID",
  SHOPPINGCART_LOCALSTORAGE_KEY_SUBSTRING: "shoppingCartDetails",
  EXCLUDED_RETURN_REASONS: ["CARRIER_RETURN", "CHANGE_COLOUR_PRODUCT"],
  ECOMMERCE_ORDER_TYPES: [
    "Ecommerce",
    "EcommerceShipFromStore",
    "EcommerceReserveOnlinePickupFromStore",
    "EcommercePickupFromStore",
  ],
  RETURNABLE_ORDER_TYPES: ["Ecommerce", "EcommerceShipFromStore", "EcommercePickupFromStore"],
  POD_RADIO_VALUE: "pickup_point" as const,
  COPIED_MESSAGE_TIMEOUT: 1500,
  ASSETS_CDN_IMAGES_SOURCE: {
    CONTENTFUL: "//images.ctfassets.net/",
    CLOUDFRONT: "//images.kikocosmetics.com/",
  },
  ASSETS_CDN_VIDEOS_SOURCE: {
    CONTENTFUL: "//videos.ctfassets.net/",
    CLOUDFRONT: "//videos.kikocosmetics.com/",
  },
  DEFAULT_RETURN_WINDOW_DAYS: 30,
  ENVIRONMENT_LABELS: ["IT", "FR"],

  /**
   * These methods cause a redirect and are not placed by the frontend.
   */
  CHECKOUT_METHODS_WITH_REDIRECT: ["alipay", "ideal", "klarna", "klarna_account", "klarna_paynow"],
};

export default Constants;
